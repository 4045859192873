<template>
  <div
    v-motion
    :initial="{
      y: 50,
      opacity: 0,
    }"
    :visibleOnce="{
      y: 0,
      opacity: 1,
      transition: {
        duration: 500,
        ease: 'easeOut',
        delay: delay || 0,
      },
    }"
  >
    <slot />
  </div>
</template>

<script>
// https://motion.vueuse.org/nuxt.html

export default {
  props: {
    delay: {
      type: Number,
      default: 0,
    },
  },
}
</script>
